import {Directive, ElementRef} from '@angular/core';


export class FusionChartsDirective {

  constructor(private el: ElementRef) {
  }

static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[appFusionchartsDirective]'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
