import {Injectable, PipeTransform, Pipe} from '@angular/core';

/**
 * Transforms any input value
 */


export class FusionChartsPipe implements PipeTransform {
  transform(value: any, args?: any[]): string {
    return value;
  }
static decorators: DecoratorInvocation[] = [
{ type: Pipe, args: [{
  name: 'fusionchartsPipe'
}, ] },
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
