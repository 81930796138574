export enum TtDataType {
    NUMBER = "NUMBER",
    TEXT = "TEXT",
    DATE = "DATE",
    ACTIONS = "ACTIONS",
    SELECT = "SELECT",
    INPUT_NUMBER = "INPUT_NUMBER",
    INPUT_DATE = "INPUT_DATE",
    INPUT_DATE_TIME = "INPUT_DATE_TIME",
    INPUT_TEXT = "INPUT_TEXT",
    INPUT_SELECT = "INPUT_SELECT"
}
