import {
  Component,
  Input,
  ElementRef,
  OnInit,
  OnChanges,
  DoCheck,
  AfterViewInit,
  OnDestroy,
  KeyValueDiffers,
  ViewChild,
  NgZone,
  Output,
  EventEmitter
} from '@angular/core';

import { FusionChartsService } from './fusioncharts.service';
import { FusionChartsConstructor } from './fusioncharts.constructor';
import FusionChartsEvent from '../interfaces/FusionChartsEvent';
import FusionChartInstance from '../interfaces/FusionChartInstance';
import EventsList from '../events/events';

class FusionChartsComponent
  implements OnInit, OnChanges, DoCheck, AfterViewInit, OnDestroy {
  chartObj: any;

   placeholder: string;
   dataSource: any;
   type: string;
   id: string;
   width: string;
   height: string;
   renderAt: string;
   dataFormat: string;
   events: string;
   link: string;
   showDataLoadingMessage: boolean;
   showChartLoadingMessage: boolean;
   baseChartMessageFont: string;
   baseChartMessageFontSize: string;
   baseChartMessageColor: string;
   dataLoadStartMessage: string;
   dataLoadErrorMessage: string;
   dataInvalidMessage: string;
   dataEmptyMessage: string;
   typeNotSupportedMessage: string;
   loadMessage: string;
   renderErrorMessage: string;
   containerBackgroundColor: string;
   containerBackgroundOpacity: string;
   containerClassName: string;
   baseChartMessageImageHAlign: string;
   baseChartMessageImageVAlign: string;
   baseChartMessageImageAlpha: number;
   baseChartMessageImageScale: number;
   typeNotSupportedMessageImageHAlign: string;
   typeNotSupportedMessageImageVAlign: string;
   typeNotSupportedMessageImageAlpha: number;
   typeNotSupportedMessageImageScale: number;
   dataLoadErrorMessageImageHAlign: string;
   dataLoadErrorMessageImageVAlign: string;
   dataLoadErrorMessageImageAlpha: number;
   dataLoadErrorMessageImageScale: number;
   dataLoadStartMessageImageHAlign: string;
   dataLoadStartMessageImageVAlign: string;
   dataLoadStartMessageImageAlpha: number;
   dataLoadStartMessageImageScale: number;
   dataInvalidMessageImageHAlign: string;
   dataInvalidMessageImageVAlign: string;
   dataInvalidMessageImageAlpha: number;
   dataInvalidMessageImageScale: number;
   dataEmptyMessageImageHAlign: string;
   dataEmptyMessageImageVAlign: string;
   dataEmptyMessageImageAlpha: number;
   dataEmptyMessageImageScale: number;
   renderErrorMessageImageHAlign: string;
   renderErrorMessageImageVAlign: string;
   renderErrorMessageImageAlpha: number;
   renderErrorMessageImageScale: number;
   loadMessageImageHAlign: string;
   loadMessageImageVAlign: string;
   loadMessageImageAlpha: number;
   loadMessageImageScale: number;
   chartConfig: string;

  /**
   * All Events List
   */

  private eventList: Array<string> = EventsList;

  /**
   * All events emitter
   */
   beforeLinkedItemOpen = new EventEmitter<FusionChartsEvent>();
   linkedItemOpened = new EventEmitter<FusionChartsEvent>();
   beforeLinkedItemClose = new EventEmitter<FusionChartsEvent>();
   linkedItemClosed = new EventEmitter<FusionChartsEvent>();
   printReadyStateChange = new EventEmitter<FusionChartsEvent>();
   dataLoadRequestCompleted = new EventEmitter<FusionChartsEvent>();
   dataLoadError = new EventEmitter<FusionChartsEvent>();
   dataLoadCancelled = new EventEmitter<FusionChartsEvent>();
   dataLoadRequestCancelled = new EventEmitter<FusionChartsEvent>();
   dataUpdated = new EventEmitter<FusionChartsEvent>();
   dataUpdateCancelled = new EventEmitter<FusionChartsEvent>();
   dataLoadRequested = new EventEmitter<FusionChartsEvent>();
   beforeDataUpdate = new EventEmitter<FusionChartsEvent>();
   realTimeUpdateComplete = new EventEmitter<FusionChartsEvent>();
   chartCleared = new EventEmitter<FusionChartsEvent>();
   slicingEnd = new EventEmitter<FusionChartsEvent>();
   slicingStart = new EventEmitter<FusionChartsEvent>();
   entityRollOut = new EventEmitter<FusionChartsEvent>();
   entityRollOver = new EventEmitter<FusionChartsEvent>();
   entityClick = new EventEmitter<FusionChartsEvent>();
   connectorRollOver = new EventEmitter<FusionChartsEvent>();
   connectorRollOut = new EventEmitter<FusionChartsEvent>();
   connectorClick = new EventEmitter<FusionChartsEvent>();
   markerRollOver = new EventEmitter<FusionChartsEvent>();
   markerRollOut = new EventEmitter<FusionChartsEvent>();
   markerClick = new EventEmitter<FusionChartsEvent>();
   pageNavigated = new EventEmitter<FusionChartsEvent>();
   rotationEnd = new EventEmitter<FusionChartsEvent>();
   rotationStart = new EventEmitter<FusionChartsEvent>();
   centerLabelRollover = new EventEmitter<FusionChartsEvent>();
   centerLabelRollout = new EventEmitter<FusionChartsEvent>();
   centerLabelClick = new EventEmitter<FusionChartsEvent>();
   centerLabelChanged = new EventEmitter<FusionChartsEvent>();
   chartClick = new EventEmitter<FusionChartsEvent>();
   chartMouseMove = new EventEmitter<FusionChartsEvent>();
   chartRollOver = new EventEmitter<FusionChartsEvent>();
   chartRollOut = new EventEmitter<FusionChartsEvent>();
   backgroundLoaded = new EventEmitter<FusionChartsEvent>();
   backgroundLoadError = new EventEmitter<FusionChartsEvent>();
   legendItemClicked = new EventEmitter<FusionChartsEvent>();
   legendItemRollover = new EventEmitter<FusionChartsEvent>();
   legendItemRollout = new EventEmitter<FusionChartsEvent>();
   logoRollover = new EventEmitter<FusionChartsEvent>();
   logoRollout = new EventEmitter<FusionChartsEvent>();
   logoClick = new EventEmitter<FusionChartsEvent>();
   logoLoaded = new EventEmitter<FusionChartsEvent>();
   logoLoadError = new EventEmitter<FusionChartsEvent>();
   beforeExport = new EventEmitter<FusionChartsEvent>();
   exported = new EventEmitter<FusionChartsEvent>();
   exportCancelled = new EventEmitter<FusionChartsEvent>();
   beforePrint = new EventEmitter<FusionChartsEvent>();
   printComplete = new EventEmitter<FusionChartsEvent>();
   printCancelled = new EventEmitter<FusionChartsEvent>();
   dataLabelClick = new EventEmitter<FusionChartsEvent>();
   dataLabelRollOver = new EventEmitter<FusionChartsEvent>();
   dataLabelRollOut = new EventEmitter<FusionChartsEvent>();
   scrollStart = new EventEmitter<FusionChartsEvent>();
   scrollEnd = new EventEmitter<FusionChartsEvent>();
   onScroll = new EventEmitter<FusionChartsEvent>();
   zoomReset = new EventEmitter<FusionChartsEvent>();
   zoomedOut = new EventEmitter<FusionChartsEvent>();
   zoomedIn = new EventEmitter<FusionChartsEvent>();
   zoomed = new EventEmitter<FusionChartsEvent>();
   zoomModeChanged = new EventEmitter<FusionChartsEvent>();
   pinned = new EventEmitter<FusionChartsEvent>();
   dataRestored = new EventEmitter<FusionChartsEvent>();
   beforeDataSubmit = new EventEmitter<FusionChartsEvent>();
   dataSubmitError = new EventEmitter<FusionChartsEvent>();
   dataSubmitted = new EventEmitter<FusionChartsEvent>();
   dataSubmitCancelled = new EventEmitter<FusionChartsEvent>();
   chartUpdated = new EventEmitter<FusionChartsEvent>();
   nodeAdded = new EventEmitter<FusionChartsEvent>();
   nodeUpdated = new EventEmitter<FusionChartsEvent>();
   nodeDeleted = new EventEmitter<FusionChartsEvent>();
   connectorAdded = new EventEmitter<FusionChartsEvent>();
   connectorUpdated = new EventEmitter<FusionChartsEvent>();
   connectorDeleted = new EventEmitter<FusionChartsEvent>();
   labelAdded = new EventEmitter<FusionChartsEvent>();
   labelDeleted = new EventEmitter<FusionChartsEvent>();
   selectionRemoved = new EventEmitter<FusionChartsEvent>();
   selectionStart = new EventEmitter<FusionChartsEvent>();
   selectionEnd = new EventEmitter<FusionChartsEvent>();
   labelClick = new EventEmitter<FusionChartsEvent>();
   labelRollOver = new EventEmitter<FusionChartsEvent>();
   labelRollOut = new EventEmitter<FusionChartsEvent>();
   labelDragStart = new EventEmitter<FusionChartsEvent>();
   labelDragEnd = new EventEmitter<FusionChartsEvent>();
   dataplotDragStart = new EventEmitter<FusionChartsEvent>();
   dataplotDragEnd = new EventEmitter<FusionChartsEvent>();
   processClick = new EventEmitter<FusionChartsEvent>();
   processRollOver = new EventEmitter<FusionChartsEvent>();
   processRollOut = new EventEmitter<FusionChartsEvent>();
   categoryClick = new EventEmitter<FusionChartsEvent>();
   categoryRollOver = new EventEmitter<FusionChartsEvent>();
   categoryRollOut = new EventEmitter<FusionChartsEvent>();
   milestoneClick = new EventEmitter<FusionChartsEvent>();
   milestoneRollOver = new EventEmitter<FusionChartsEvent>();
   milestoneRollOut = new EventEmitter<FusionChartsEvent>();
   chartTypeChanged = new EventEmitter<FusionChartsEvent>();
   overlayButtonClick = new EventEmitter<FusionChartsEvent>();
   loaded = new EventEmitter<FusionChartsEvent>();
   rendered = new EventEmitter<FusionChartsEvent>();
   drawComplete = new EventEmitter<FusionChartsEvent>();
   renderComplete = new EventEmitter<FusionChartsEvent>();
   dataInvalid = new EventEmitter<FusionChartsEvent>();
   dataXMLInvalid = new EventEmitter<FusionChartsEvent>();
   dataLoaded = new EventEmitter<FusionChartsEvent>();
   noDataToDisplay = new EventEmitter<FusionChartsEvent>();
   legendPointerDragStart = new EventEmitter<FusionChartsEvent>();
   legendPointerDragStop = new EventEmitter<FusionChartsEvent>();
   legendRangeUpdated = new EventEmitter<FusionChartsEvent>();
   alertComplete = new EventEmitter<FusionChartsEvent>();
   realTimeUpdateError = new EventEmitter<FusionChartsEvent>();
   dataplotRollOver = new EventEmitter<FusionChartsEvent>();
   dataplotRollOut = new EventEmitter<FusionChartsEvent>();
   dataplotClick = new EventEmitter<FusionChartsEvent>();
   linkClicked = new EventEmitter<FusionChartsEvent>();
   beforeRender = new EventEmitter<FusionChartsEvent>();
   renderCancelled = new EventEmitter<FusionChartsEvent>();
   beforeResize = new EventEmitter<FusionChartsEvent>();
   resized = new EventEmitter<FusionChartsEvent>();
   resizeCancelled = new EventEmitter<FusionChartsEvent>();
   beforeDispose = new EventEmitter<FusionChartsEvent>();
   disposed = new EventEmitter<FusionChartsEvent>();
   disposeCancelled = new EventEmitter<FusionChartsEvent>();
   linkedChartInvoked = new EventEmitter<FusionChartsEvent>();
   beforeDrillDown = new EventEmitter<FusionChartsEvent>();
   drillDown = new EventEmitter<FusionChartsEvent>();
   beforeDrillUp = new EventEmitter<FusionChartsEvent>();
   drillUp = new EventEmitter<FusionChartsEvent>();
   drillDownCancelled = new EventEmitter<FusionChartsEvent>();
   drillUpCancelled = new EventEmitter<FusionChartsEvent>();
   initialized = new EventEmitter<FusionChartInstance>();

  containerId: string;
  private configObj: any;
  private oldDataSource: any = this.dataSource;
  private oldDataTable: any;
  private constructerParams = {
    type: true,
    id: true,
    width: true,
    height: true,
    renderAt: true,
    dataFormat: true,
    dataSource: true,
    events: true,
    link: true,
    showDataLoadingMessage: true,
    showChartLoadingMessage: true,
    baseChartMessageFont: true,
    baseChartMessageFontSize: true,
    baseChartMessageColor: true,
    dataLoadStartMessage: true,
    dataLoadErrorMessage: true,
    dataInvalidMessage: true,
    dataEmptyMessage: true,
    typeNotSupportedMessage: true,
    loadMessage: true,
    renderErrorMessage: true,
    containerBackgroundColor: true,
    containerBackgroundOpacity: true,
    containerClassName: true,
    baseChartMessageImageHAlign: true,
    baseChartMessageImageVAlign: true,
    baseChartMessageImageAlpha: true,
    baseChartMessageImageScale: true,
    typeNotSupportedMessageImageHAlign: true,
    typeNotSupportedMessageImageVAlign: true,
    typeNotSupportedMessageImageAlpha: true,
    typeNotSupportedMessageImageScale: true,
    dataLoadErrorMessageImageHAlign: true,
    dataLoadErrorMessageImageVAlign: true,
    dataLoadErrorMessageImageAlpha: true,
    dataLoadErrorMessageImageScale: true,
    dataLoadStartMessageImageHAlign: true,
    dataLoadStartMessageImageVAlign: true,
    dataLoadStartMessageImageAlpha: true,
    dataLoadStartMessageImageScale: true,
    dataInvalidMessageImageHAlign: true,
    dataInvalidMessageImageVAlign: true,
    dataInvalidMessageImageAlpha: true,
    dataInvalidMessageImageScale: true,
    dataEmptyMessageImageHAlign: true,
    dataEmptyMessageImageVAlign: true,
    dataEmptyMessageImageAlpha: true,
    dataEmptyMessageImageScale: true,
    renderErrorMessageImageHAlign: true,
    renderErrorMessageImageVAlign: true,
    renderErrorMessageImageAlpha: true,
    renderErrorMessageImageScale: true,
    loadMessageImageHAlign: true,
    loadMessageImageVAlign: true,
    loadMessageImageAlpha: true,
    loadMessageImageScale: true,
    chartConfig: true
  };
  element: ElementRef;
  fusionchartsService: FusionChartsService;

  constructor(
    element: ElementRef,
    fusionchartsService: FusionChartsService,
    private differs: KeyValueDiffers,
    private zone: NgZone
  ) {
    this.element = element;
    this.fusionchartsService = fusionchartsService;
    this.containerId = fusionchartsService.getNextItemCount();
  }

  // @ViewChild('samplediv') chartContainer: ElementRef;

  checkIfDataTableExists(dataSource) {
    if (dataSource && dataSource.data && dataSource.data._dataStore) {
      return true;
    }
    return false;
  }

  cloneDataSource(obj) {
    let type = typeof obj;
    if (
      type === 'string' ||
      type === 'number' ||
      type === 'function' ||
      type === 'boolean'
    ) {
      return obj;
    }
    if (obj === null || obj === undefined) {
      return obj;
    }
    if (Array.isArray(obj)) {
      let arr: any[] = [];
      for (let i = 0; i < obj.length; i++) {
        arr.push(this.cloneDataSource(obj[i]));
      }
      return arr;
    }
    if (typeof obj === 'object') {
      let clonedObj = {};
      for (let prop in obj) {
        // Edge case handling for DataTable
        if (prop === 'data') {
          if (obj[prop]._dataStore) {
            clonedObj[prop] = `-`;
          } else {
            clonedObj[prop] = this.cloneDataSource(obj[prop]);
          }
          continue;
        }
        clonedObj[prop] = this.cloneDataSource(obj[prop]);
      }
      return clonedObj;
    }
  }

  ngOnInit() {
    if (this.checkIfDataTableExists(this.dataSource)) {
      this.oldDataSource = JSON.stringify(
        this.cloneDataSource(this.dataSource)
      );
    } else {
      this.oldDataSource = JSON.stringify(this.dataSource);
    }
    this.placeholder = this.placeholder || 'FusionCharts will render here';
  }

  ngOnChanges(changes: any) {
    for (const i of Object.keys(changes)) {
      const key = i.charAt(0).toUpperCase() + i.slice(1),
        THIS = this,
        fnName = `update${key}`;
      if (THIS[fnName]) {
        THIS[fnName]();
      }
    }
  }

  ngDoCheck() {
    let data;
    if (this.checkIfDataTableExists(this.dataSource)) {
      data = JSON.stringify(this.cloneDataSource(this.dataSource));
    } else {
      data = JSON.stringify(this.dataSource);
    }
    if (
      this.oldDataSource !== data ||
      this.oldDataTable !== this.dataSource.data
    ) {
      this.oldDataTable = this.dataSource && this.dataSource.data;
      this.oldDataSource = data;
      this.updateChartData();
    }
  }

  updateChartData() {
    const dataFormat =
        this.configObj && this.configObj.dataFormat
          ? this.configObj.dataFormat
          : 'json',
      data = this.dataSource;

    if (this.chartObj) {
      this.chartObj.setChartData(data, dataFormat);
    }
  }

  updateWidth() {
    if (this.chartObj) {
      this.chartObj.resizeTo({
        w: this.width
      });
    }
  }

  updateHeight() {
    if (this.chartObj) {
      this.chartObj.resizeTo({
        h: this.height
      });
    }
  }

  updateType() {
    if (this.chartObj) {
      this.chartObj.chartType(this.type);
    }
  }

  generateEventsCallback(eventList: Array<string>) {
    let events = {};
    eventList.forEach(eventName => {
      events[eventName] = (eventObj: any, dataObj: any) => {
        let fEventObj: FusionChartsEvent = { eventObj: {}, dataObj: {} };
        if (eventObj) fEventObj.eventObj = eventObj;
        if (dataObj) fEventObj.dataObj = dataObj;
        this[eventName].emit(fEventObj);
      };
    });
    return events;
  }

  ngAfterViewInit() {
    const _this = this,
      params = _this.constructerParams,
      configObj = _this.configObj || (_this.configObj = {});

    let _chartConfig: any = _this.chartConfig || {};

    if (typeof _chartConfig === 'string') {
      _chartConfig = JSON.parse(_chartConfig);
    }

    for (const i of Object.keys(params)) {
      const value = _this[i] || _chartConfig[i];
      if (value) {
        configObj[i] = value;
      }
    }

    if (configObj['type']) {
      let events = _this.generateEventsCallback(_this.eventList);
      if (!configObj['events']) {
        configObj['events'] = events;
      } else {
        configObj['events'] = Object.assign(events, configObj['events']);
      }

      // configObj['renderAt'] = 'container-' + _this.chartObj.id;
      // _this.containerId = _this.chartObj.id;

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          _this.chartObj = FusionChartsConstructor(
            _this.fusionchartsService,
            configObj
          );
          this.initialized.emit({ chart: _this.chartObj });
          _this.chartObj.render(
            _this.element.nativeElement.querySelector('div')
          );
        }, 1);
      });
    }
  }

  ngOnDestroy() {
    this.chartObj && this.chartObj.dispose();
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'fusioncharts',
  template: `
    <div attr.id="container-{{ containerId }}" style="width:100%;height:100%">
      {{ placeholder }}
    </div>
  `,
  providers: [FusionChartsService]
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: FusionChartsService, },
{type: KeyValueDiffers, },
{type: NgZone, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'placeholder': [{ type: Input },],
'dataSource': [{ type: Input },],
'type': [{ type: Input },],
'id': [{ type: Input },],
'width': [{ type: Input },],
'height': [{ type: Input },],
'renderAt': [{ type: Input },],
'dataFormat': [{ type: Input },],
'events': [{ type: Input },],
'link': [{ type: Input },],
'showDataLoadingMessage': [{ type: Input },],
'showChartLoadingMessage': [{ type: Input },],
'baseChartMessageFont': [{ type: Input },],
'baseChartMessageFontSize': [{ type: Input },],
'baseChartMessageColor': [{ type: Input },],
'dataLoadStartMessage': [{ type: Input },],
'dataLoadErrorMessage': [{ type: Input },],
'dataInvalidMessage': [{ type: Input },],
'dataEmptyMessage': [{ type: Input },],
'typeNotSupportedMessage': [{ type: Input },],
'loadMessage': [{ type: Input },],
'renderErrorMessage': [{ type: Input },],
'containerBackgroundColor': [{ type: Input },],
'containerBackgroundOpacity': [{ type: Input },],
'containerClassName': [{ type: Input },],
'baseChartMessageImageHAlign': [{ type: Input },],
'baseChartMessageImageVAlign': [{ type: Input },],
'baseChartMessageImageAlpha': [{ type: Input },],
'baseChartMessageImageScale': [{ type: Input },],
'typeNotSupportedMessageImageHAlign': [{ type: Input },],
'typeNotSupportedMessageImageVAlign': [{ type: Input },],
'typeNotSupportedMessageImageAlpha': [{ type: Input },],
'typeNotSupportedMessageImageScale': [{ type: Input },],
'dataLoadErrorMessageImageHAlign': [{ type: Input },],
'dataLoadErrorMessageImageVAlign': [{ type: Input },],
'dataLoadErrorMessageImageAlpha': [{ type: Input },],
'dataLoadErrorMessageImageScale': [{ type: Input },],
'dataLoadStartMessageImageHAlign': [{ type: Input },],
'dataLoadStartMessageImageVAlign': [{ type: Input },],
'dataLoadStartMessageImageAlpha': [{ type: Input },],
'dataLoadStartMessageImageScale': [{ type: Input },],
'dataInvalidMessageImageHAlign': [{ type: Input },],
'dataInvalidMessageImageVAlign': [{ type: Input },],
'dataInvalidMessageImageAlpha': [{ type: Input },],
'dataInvalidMessageImageScale': [{ type: Input },],
'dataEmptyMessageImageHAlign': [{ type: Input },],
'dataEmptyMessageImageVAlign': [{ type: Input },],
'dataEmptyMessageImageAlpha': [{ type: Input },],
'dataEmptyMessageImageScale': [{ type: Input },],
'renderErrorMessageImageHAlign': [{ type: Input },],
'renderErrorMessageImageVAlign': [{ type: Input },],
'renderErrorMessageImageAlpha': [{ type: Input },],
'renderErrorMessageImageScale': [{ type: Input },],
'loadMessageImageHAlign': [{ type: Input },],
'loadMessageImageVAlign': [{ type: Input },],
'loadMessageImageAlpha': [{ type: Input },],
'loadMessageImageScale': [{ type: Input },],
'chartConfig': [{ type: Input },],
'beforeLinkedItemOpen': [{ type: Output },],
'linkedItemOpened': [{ type: Output },],
'beforeLinkedItemClose': [{ type: Output },],
'linkedItemClosed': [{ type: Output },],
'printReadyStateChange': [{ type: Output },],
'dataLoadRequestCompleted': [{ type: Output },],
'dataLoadError': [{ type: Output },],
'dataLoadCancelled': [{ type: Output },],
'dataLoadRequestCancelled': [{ type: Output },],
'dataUpdated': [{ type: Output },],
'dataUpdateCancelled': [{ type: Output },],
'dataLoadRequested': [{ type: Output },],
'beforeDataUpdate': [{ type: Output },],
'realTimeUpdateComplete': [{ type: Output },],
'chartCleared': [{ type: Output },],
'slicingEnd': [{ type: Output },],
'slicingStart': [{ type: Output },],
'entityRollOut': [{ type: Output },],
'entityRollOver': [{ type: Output },],
'entityClick': [{ type: Output },],
'connectorRollOver': [{ type: Output },],
'connectorRollOut': [{ type: Output },],
'connectorClick': [{ type: Output },],
'markerRollOver': [{ type: Output },],
'markerRollOut': [{ type: Output },],
'markerClick': [{ type: Output },],
'pageNavigated': [{ type: Output },],
'rotationEnd': [{ type: Output },],
'rotationStart': [{ type: Output },],
'centerLabelRollover': [{ type: Output },],
'centerLabelRollout': [{ type: Output },],
'centerLabelClick': [{ type: Output },],
'centerLabelChanged': [{ type: Output },],
'chartClick': [{ type: Output },],
'chartMouseMove': [{ type: Output },],
'chartRollOver': [{ type: Output },],
'chartRollOut': [{ type: Output },],
'backgroundLoaded': [{ type: Output },],
'backgroundLoadError': [{ type: Output },],
'legendItemClicked': [{ type: Output },],
'legendItemRollover': [{ type: Output },],
'legendItemRollout': [{ type: Output },],
'logoRollover': [{ type: Output },],
'logoRollout': [{ type: Output },],
'logoClick': [{ type: Output },],
'logoLoaded': [{ type: Output },],
'logoLoadError': [{ type: Output },],
'beforeExport': [{ type: Output },],
'exported': [{ type: Output },],
'exportCancelled': [{ type: Output },],
'beforePrint': [{ type: Output },],
'printComplete': [{ type: Output },],
'printCancelled': [{ type: Output },],
'dataLabelClick': [{ type: Output },],
'dataLabelRollOver': [{ type: Output },],
'dataLabelRollOut': [{ type: Output },],
'scrollStart': [{ type: Output },],
'scrollEnd': [{ type: Output },],
'onScroll': [{ type: Output },],
'zoomReset': [{ type: Output },],
'zoomedOut': [{ type: Output },],
'zoomedIn': [{ type: Output },],
'zoomed': [{ type: Output },],
'zoomModeChanged': [{ type: Output },],
'pinned': [{ type: Output },],
'dataRestored': [{ type: Output },],
'beforeDataSubmit': [{ type: Output },],
'dataSubmitError': [{ type: Output },],
'dataSubmitted': [{ type: Output },],
'dataSubmitCancelled': [{ type: Output },],
'chartUpdated': [{ type: Output },],
'nodeAdded': [{ type: Output },],
'nodeUpdated': [{ type: Output },],
'nodeDeleted': [{ type: Output },],
'connectorAdded': [{ type: Output },],
'connectorUpdated': [{ type: Output },],
'connectorDeleted': [{ type: Output },],
'labelAdded': [{ type: Output },],
'labelDeleted': [{ type: Output },],
'selectionRemoved': [{ type: Output },],
'selectionStart': [{ type: Output },],
'selectionEnd': [{ type: Output },],
'labelClick': [{ type: Output },],
'labelRollOver': [{ type: Output },],
'labelRollOut': [{ type: Output },],
'labelDragStart': [{ type: Output },],
'labelDragEnd': [{ type: Output },],
'dataplotDragStart': [{ type: Output },],
'dataplotDragEnd': [{ type: Output },],
'processClick': [{ type: Output },],
'processRollOver': [{ type: Output },],
'processRollOut': [{ type: Output },],
'categoryClick': [{ type: Output },],
'categoryRollOver': [{ type: Output },],
'categoryRollOut': [{ type: Output },],
'milestoneClick': [{ type: Output },],
'milestoneRollOver': [{ type: Output },],
'milestoneRollOut': [{ type: Output },],
'chartTypeChanged': [{ type: Output },],
'overlayButtonClick': [{ type: Output },],
'loaded': [{ type: Output },],
'rendered': [{ type: Output },],
'drawComplete': [{ type: Output },],
'renderComplete': [{ type: Output },],
'dataInvalid': [{ type: Output },],
'dataXMLInvalid': [{ type: Output },],
'dataLoaded': [{ type: Output },],
'noDataToDisplay': [{ type: Output },],
'legendPointerDragStart': [{ type: Output },],
'legendPointerDragStop': [{ type: Output },],
'legendRangeUpdated': [{ type: Output },],
'alertComplete': [{ type: Output },],
'realTimeUpdateError': [{ type: Output },],
'dataplotRollOver': [{ type: Output },],
'dataplotRollOut': [{ type: Output },],
'dataplotClick': [{ type: Output },],
'linkClicked': [{ type: Output },],
'beforeRender': [{ type: Output },],
'renderCancelled': [{ type: Output },],
'beforeResize': [{ type: Output },],
'resized': [{ type: Output },],
'resizeCancelled': [{ type: Output },],
'beforeDispose': [{ type: Output },],
'disposed': [{ type: Output },],
'disposeCancelled': [{ type: Output },],
'linkedChartInvoked': [{ type: Output },],
'beforeDrillDown': [{ type: Output },],
'drillDown': [{ type: Output },],
'beforeDrillUp': [{ type: Output },],
'drillUp': [{ type: Output },],
'drillDownCancelled': [{ type: Output },],
'drillUpCancelled': [{ type: Output },],
'initialized': [{ type: Output },],
};
}
export { FusionChartsComponent };

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
