import { NgModule } from '@angular/core';
import { AgChartsAngular } from './ag-charts-angular.component';



@NgModule({
  declarations: [AgChartsAngular],
  imports: [
  ],
  exports: [AgChartsAngular]
})
export class AgChartsAngularModule { }
