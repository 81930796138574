/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {BROWSER_MODULE_PROVIDERS as ɵangular_packages_platform_browser_platform_browser_c,_document as ɵangular_packages_platform_browser_platform_browser_b,errorHandler as ɵangular_packages_platform_browser_platform_browser_a} from './src/browser';
export {GenericBrowserDomAdapter as ɵangular_packages_platform_browser_platform_browser_p} from './src/browser/generic_browser_adapter';
export {createMeta as ɵangular_packages_platform_browser_platform_browser_d} from './src/browser/meta';
export {SERVER_TRANSITION_PROVIDERS as ɵangular_packages_platform_browser_platform_browser_l,appInitializerFactory as ɵangular_packages_platform_browser_platform_browser_k} from './src/browser/server-transition';
export {createTitle as ɵangular_packages_platform_browser_platform_browser_e} from './src/browser/title';
export {initTransferState as ɵangular_packages_platform_browser_platform_browser_f} from './src/browser/transfer_state';
export {BrowserXhr as ɵangular_packages_platform_browser_platform_browser_o} from './src/browser/xhr';
export {ELEMENT_PROBE_PROVIDERS__PRE_R3__ as ɵangular_packages_platform_browser_platform_browser_n,_createNgProbeR2 as ɵangular_packages_platform_browser_platform_browser_m} from './src/dom/debug/ng_probe';
export {EventManagerPlugin as ɵangular_packages_platform_browser_platform_browser_g} from './src/dom/events/event_manager';
export {HAMMER_PROVIDERS as ɵangular_packages_platform_browser_platform_browser_i,HAMMER_PROVIDERS__PRE_R3__ as ɵangular_packages_platform_browser_platform_browser_h} from './src/dom/events/hammer_gestures';
export {domSanitizerImplFactory as ɵangular_packages_platform_browser_platform_browser_j} from './src/security/dom_sanitization_service';